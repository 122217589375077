import React from 'react'
import { useState } from 'react';
import Validate from '../components/validation.js';
import FieldText from '../components/field_text.js';
import FieldEmail from '../components/field_email.js';
import FieldSelect from '../components/field_select.js';
import FieldTextArea from '../components/field_textarea.js';
import FieldErrorMessage from '../components/field_error_message.js';
import ReCAPTCHA from "react-google-recaptcha";

const SITE_KEY = process.env.GATSBY_PUBLIC_RECAPTCHA_SITE_KEY;

const FormContact = () => 
{
    const recaptchaRef = React.createRef();
    const [formValues, setFormValues] = useState({
        formErrors: '',
        formIsValid: false,
        formControls: {
            full_name: {
                type: 'text',
                value: '',
                placeholder: 'Your name',
                valid: false,
                touched: false,
                validationRules: {
                    isRequired:  true,
                },
                errorMessages: ''
            },
            email: {
                type: 'email',
                value: '',
                placeholder: 'Your email',
                valid: false,
                touched: false,
                validationRules: {
                    isRequired: true,
                    isEmail: true
                },
                errorMessages: ''
            },
            phone: {
                type: 'text',
                value: '',
                placeholder: 'Your phone',
                valid: false,
                touched: false,
                validationRules: {
                    isRequired: true
                },
                errorMessages: ''
            },
            location: {
                type: 'select',
                options: [
                    { value: '', displayValue: 'Location' },
                    { value: 'Auckland', displayValue: 'Auckland' },
                    { value: 'Wellington', displayValue: 'Wellington' },
                    { value: 'Christchurch', displayValue: 'Christchurch' },
                ],
                valid: false,
                touched: false,
                validationRules: {
                    isRequired: false
                },
                errorMessages: ''
            },
            message: {
                type: 'text',
                value: '',
                placeholder: 'Message',
                valid: false,
                touched: false,
                validationRules: {
                    isRequired: true
                },
                errorMessages: ''
            },
        }
    });

    const changeHandler = e => {
        const name = e.target.name;
        const value = e.target.value;

        const updatedControls = {
            ...formValues.formControls
        };
        const updatedFormElement = {
            ...updatedControls[name]
        };
        updatedFormElement.value = value;
        updatedFormElement.touched = true;

        let validateFormElement = [];
        validateFormElement = Validate(value, updatedFormElement.validationRules);

        updatedFormElement.valid = validateFormElement.isValid;
        updatedFormElement.errorMessages = validateFormElement.errorMessages;

        updatedControls[name] = updatedFormElement;

        let formIsValid = true;
        for (let inputIdentifier in updatedControls) {
            formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
        }

        setFormValues({
            ...formValues,
            formControls: updatedControls,
            formIsValid: formIsValid,
        });
    }

    const submitHandler = async e => 
    {
        e.preventDefault();
        e.persist();
        
        const formData = {};
        for (let formElementId in formValues.formControls) {
            formData[formElementId] = formValues.formControls[formElementId].value
        }
        
        const token = await recaptchaRef.current.executeAsync();
        formData.recaptcha = token;

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let product = params.get('product');

        if (product) {
            formData.product = product;
        }
 
        const res = await fetch('/api/contact', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });
        
        if (res.status === 200) {
            window.location.href = '/contact-us/thanks/';
            return
        }

        if (res.status === 422) {
            let errors = await res.json();
            setFormValues({
                ...formValues,
                formErrors: errors.message,
            });
            return
        };

        if (res.status === 404) {
            let errors = await res.json();
            setFormValues({
                ...formValues,
                formErrors: errors.message,
            });
            return
        };
    }

    return (
        <form name="contact" className="form" onSubmit={submitHandler}>
            <FieldText
                id="full_name"
                name="full_name"
                type={formValues.formControls.full_name.type}
                placeholder={formValues.formControls.full_name.placeholder}
                value={formValues.formControls.full_name.value}
                onChange={changeHandler}
                className={formValues.formControls.full_name.touched && !formValues.formControls.full_name.valid ? 'field__input error' : 'field__input'}
            />
            <FieldErrorMessage
                className={formValues.formControls.full_name.touched && !formValues.formControls.full_name.valid ? 'show field__errors' : 'hide field__errors'}
                errorMessages={formValues.formControls.full_name.errorMessages}
            />
            <FieldEmail
                id="email"
                name="email"
                type={formValues.formControls.email.type}
                placeholder={formValues.formControls.email.placeholder}
                value={formValues.formControls.email.value}
                onChange={changeHandler}
                className={formValues.formControls.email.touched && !formValues.formControls.email.valid ? 'field__input error' : 'field__input'}
            />
            <FieldErrorMessage
                className={formValues.formControls.email.touched && !formValues.formControls.email.valid ? 'show field__errors' : 'hide field__errors'}
                errorMessages={formValues.formControls.email.errorMessages}
            />
            <FieldText
                id="phone"
                name="phone"
                type={formValues.formControls.phone.type}
                placeholder={formValues.formControls.phone.placeholder}
                value={formValues.formControls.phone.value}
                onChange={changeHandler}
                className={formValues.formControls.phone.touched && !formValues.formControls.phone.valid ? 'field__input error' : 'field__input'}
            />
            <FieldErrorMessage
                className={formValues.formControls.phone.touched && !formValues.formControls.phone.valid ? 'show field__errors' : 'hide field__errors'}
                errorMessages={formValues.formControls.phone.errorMessages}
            />
            <FieldSelect
                id="location"
                name="location"
                type={formValues.formControls.location.type}
                options={formValues.formControls.location.options}
                onChange={changeHandler}
                className={formValues.formControls.location.touched && !formValues.formControls.location.valid ? 'select_contact error' : 'select_contact'}
            />
            <FieldErrorMessage
                className={formValues.formControls.location.touched && !formValues.formControls.location.valid ? 'show field__errors' : 'hide field__errors'}
                errorMessages={formValues.formControls.location.errorMessages}
            />
            <FieldTextArea
                id="message"
                name="message"
                type={formValues.formControls.message.type}
                placeholder={formValues.formControls.message.placeholder}
                value={formValues.formControls.message.value}
                onChange={changeHandler}
                className={formValues.formControls.message.touched && !formValues.formControls.message.valid ? 'field__textarea error' : 'field__textarea'}
            />
            <FieldErrorMessage
                className={formValues.formControls.message.touched && !formValues.formControls.message.valid ? 'show field__errors' : 'hide field__errors'}
                errorMessages={formValues.formControls.message.errorMessages}
            />
            <ReCAPTCHA
                size="invisible"
                ref={recaptchaRef}
                sitekey={SITE_KEY}
            />
            <button type="submit"
                className="send"
                disabled={!formValues.formIsValid}
            >
                Send &rsaquo;
            </button>
        </form>
    )
}

export default FormContact